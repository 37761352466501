import React, { useRef, useState } from "react";

const VerifyOtp = ({ show, onHide, handleSubmit }) => {
  const [otp, setOTP] = useState(["", "", "", ""]);
  const inputRefs = useRef([null, null, null, null]);

  const handleChange = (index, value) => {
    if (isNaN(Number(value))) return; // Only allow numeric input
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    // Move to next input field if a digit is entered
    if (value && index < 3 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleVerify = async () => {
    const enteredOTP = otp.join("");

    handleSubmit(enteredOTP);
  };

  // Check if all OTP boxes are filled
  const isOtpComplete = otp.every((digit) => digit !== "");

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center signup-otp-verify-wrp ${
        show ? "" : "d-none"
      }`}
    >
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>

      {/* Modal Content */}
      <div className="bg-white rounded-lg p-8 max-w-md w-full z-50 shadow-lg mx-2 signup-otp-verify-content">
        {/* Modal Header */}
        <h2 className="text-xl font-semibold mb-4 text-center">Verify Email</h2>

        {/* OTP Input Fields */}
        <div className="flex justify-between gap-2 signup-otp-verify-input-wrp">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              maxLength={1}
              className="w-1/4 h-12 border border-gray-400 rounded text-center focus-visible:outline-0"
              value={digit}
              onChange={(event) => handleChange(index, event.target.value)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              ref={(input) => {
                inputRefs.current[index] = input;
              }}
            />
          ))}
        </div>

        {/* Action Buttons */}
        <div className="mt-4 d-flex align-items-center justify-content-center gap-3">
          <button
            className="cmn-btn"
            onClick={handleVerify}
            disabled={!isOtpComplete}
          >
            Verify Otp
          </button>
          <button
            className="cmn-btn signup-otp-verify-cancel"
            onClick={() => {
              setOTP(["", "", "", ""]);
              onHide();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
